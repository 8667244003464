import React from "react"
import Layout from "../components/layout"

export default function Lost() {
  return (
    <Layout title="Oh No! Page Can't Be Found">
      <a href="/">
        <div className="cta cta-body">
          <h2>Take me Home</h2>
        </div>
      </a>
    </Layout>
  )
}
